//base
.link{
	color: $color-yellow;
	&:hover{
		color: $color-yellow;
	}
}

//buttons
.btn:focus, .btn:active, 
button:focus, button:active,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, 
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus, 
.show > .btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: none !important;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, 
.btn-outline-secondary:not(:disabled):not(.disabled).active, 
.show > .btn-outline-secondary.dropdown-toggle{
    background-color: transparent;
    border-color: transparent;
}
.btn-custom-line--selected{
	color: $color-white;
}
.btn-custom-video{
    border-bottom: 1.5px solid $color-dark;
    color: $color-dark;
	&:hover{
        color: $color-dark;
	}
}
.btn-custom--full{
    color: $color-dark;
	&:hover{
        background: $color-orange;
        border: 2px solid $color-orange;
        color: $color-dark;
    }
}
.btn--link{
	color: $color-orange;
	&:hover{
        color: $color-orange;
    }
}
button:disabled > .fac.fac-vpred {
    filter: brightness(0) invert(1);
}
.btn:hover:disabled{
    background: $color-inactive !important;
    color: $color-white !important;
    border: 2px solid $color-inactive !important;
    box-shadow: none; 
}
//1-homepage
.homepage{
    color: $color-dark;
}
.h2-homepage{
    color: $color-dark;
    font-size: 85px;
    line-height: 1.3;
    @include screen-lg-xl{
        font-size: 70px;
        line-height: 1.2;
    }
    @include screen-md-lg{
        font-size: 80px;
        line-height: 1.2;
    }
    @include screen-sm-md{
        font-size: 60px;
        line-height: 1.2;
    }
    @include screen-xs-sm{
        font-size: 40px;
        line-height: 1.2;
    }
}
.homepage-hp-logo{
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    @include screen-xs-sm{
        > a {
            margin: auto;
        }
    }
}
.homepage__logos{
    img{
        margin: 0 auto;
        max-height: 70px;
        max-width: 100%;
    }
}
.homepage-logo{
    height: 70px;
    align-items: center;
    flex-wrap: wrap;
    a{
        display: flex;
        height: 100%;
        width: 100%;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
}
.homepage-header{
    flex-wrap: nowrap;
    align-items: center;
    @include screen-max-md{
        flex-wrap: wrap;
        height: auto !important;
        padding-bottom: 16px !important;
    }
}  
//3-vlastnosti
.vlastnosti-povolani_filtered{
    background-color: $color-light_yellow;
}
//9analyza
.prague-container{
    background: $color-light-yellow;
}
//main
.drobeckova_polozka{
    &--active{
        color: $color-dark;
    }
    &:not(.drobeckova_polozka--disabled):not(.drobeckova_polozka--active):hover {
        background: $color-yellow; 
    }
}
.green-color{
    color: $color-yellow;
}
.povolani-header-background{
    background: $color-light-yellow;
}
.vlastnosti-container{
    &--line{
        border: 2px solid $color-yellow;
    }
    &--full{
        background: $color-yellow;
        color: $color-dark;
    }
}
.vlastnosti-povolani-box{
    color: $color-dark;
}
.karta-header-background{
    background: $color-yellow;
}
.light-section{
    background: $color-light-yellow;
}
.analyze-table{
    & .w-100{
        background: linear-gradient(135deg, $color-gradient-1 0%, $color-gradient-2 100%);
    }
}
.rezerva-header-background{
    background: $color-light-yellow;
}
.input-number-group {
    .input-number {
        background: $color-yellow;
    }
    .input-number--zero{
        background: $color-white;
        border: 1px solid $color-yellow !important;
    }
}
.container-sub{  
    & input[type=radio]:checked + label { 
        color: $color-yellow !important;
    }
    & .subselect[type=radio]:checked + label { 
        color: $color-yellow !important;
    }  
    & .subselect[type=radio]:hover{
        border: 1px solid $color-yellow !important;
    } 
    & .custom-control-label::before{
        border: $color-yellow solid 1px !important;
    }
    & .custom-control-label:hover{ 
        color: $color-yellow !important;
    } 
}
.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default, .ui-button, html .ui-button.ui-state-disabled:hover, html .ui-button.ui-state-disabled:active{
    background: $color-yellow !important;
    border: 1px solid $color-yellow !important;
}
.ui-state-hover, .ui-widget-content .ui-state-hover, .ui-widget-header .ui-state-hover{
    background: $color-yellow !important;
}
.ui-state-focus, .ui-widget-content .ui-state-focus, .ui-widget-header .ui-state-focuse{
    background: $color-yellow !important;
}
.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active, .ui-button:active, a.ui-button:active, .ui-button.ui-state-active:hover{
    border: 1px solid $color-yellow !important;
}
.background-green{
    background: $color-button !important;  
} 