$color-white: #fff;
$color-black: #000;

$color-dark: #333333;
$color-blue: #00E0FF;
$color-light: #E4FCFF;
$color-inactive: #D5D5D5;
$color-hover: #FF5C00; //00BAAF;
$color-button: #f6a016; //00CABE;

$color-orange:  #FF5C00;
$color-yellow: #f6a016;
$color-light_yellow: #FFF5D9;

$color-gradient-1: #F37201;
$color-gradient-2: #FFCF82; 

$map-border: #F17100;
$map-bg: #F6A016; 
$map-inactive: #D5D5D5;
$map-inactive-border: #BDBDBD;
$map-current-point: #00CABE;
$map-current-point-bg: #009990;

/** boostrap color replace - checkboxy **/
$blue: #f6a016;
 
@import "C:/wamp64/www/mujzivotposkole/resources/src/scss/abstracts/_colors.scss";
@import "C:/wamp64/www/mujzivotposkole/resources/src/scss/abstracts/_em.scss";
@import "C:/wamp64/www/mujzivotposkole/resources/src/scss/abstracts/_fonts.scss";
@import "C:/wamp64/www/mujzivotposkole/resources/src/scss/abstracts/_variables.scss";
@import "./../../bootstrap/bootstrap.scss";
@import "./../../breakpoints";

@import "before.scss"; 
@import "C:/wamp64/www/mujzivotposkole/resources/src/scss/base/_base.scss";
@import "C:/wamp64/www/mujzivotposkole/resources/src/scss/layout/_1-1-podklady.scss";
@import "C:/wamp64/www/mujzivotposkole/resources/src/scss/layout/_1-homepage.scss";
@import "C:/wamp64/www/mujzivotposkole/resources/src/scss/layout/_11-final.scss";
@import "C:/wamp64/www/mujzivotposkole/resources/src/scss/layout/_2-rozcestnik.scss";
@import "C:/wamp64/www/mujzivotposkole/resources/src/scss/layout/_3-vlastnosti.scss";
@import "C:/wamp64/www/mujzivotposkole/resources/src/scss/layout/_4-kartapovolani.scss";
@import "C:/wamp64/www/mujzivotposkole/resources/src/scss/layout/_4-vsechnyprofese.scss";
@import "C:/wamp64/www/mujzivotposkole/resources/src/scss/layout/_9-analyza.scss";
@import "C:/wamp64/www/mujzivotposkole/resources/src/scss/layout/_aside.scss";
@import "C:/wamp64/www/mujzivotposkole/resources/src/scss/layout/_footer.scss";
@import "C:/wamp64/www/mujzivotposkole/resources/src/scss/layout/_header.scss";
@import "C:/wamp64/www/mujzivotposkole/resources/src/scss/layout/_main.scss";
@import "C:/wamp64/www/mujzivotposkole/resources/src/scss/layout/_navigation-bar.scss";

@import "C:/wamp64/www/mujzivotposkole/resources/src/scss/components/_buttons.scss";
@import "C:/wamp64/www/mujzivotposkole/resources/src/scss/components/_forms.scss";
@import "C:/wamp64/www/mujzivotposkole/resources/src/scss/components/_icons.scss";
@import "C:/wamp64/www/mujzivotposkole/resources/src/scss/components/_stars.scss";
@import "C:/wamp64/www/mujzivotposkole/resources/src/scss/components/_svg.scss"; 
@import "position-boxes.scss"; 
@import "after.scss"; 