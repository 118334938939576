/*
  xs: 0			 - 576px
  sm: em(576px), - 768px
  md: em(768px), - 992px
  lg: em(992px), - 1200px
  xl: em(1200px) - .... 

  screen-min-xl     1200+
  screen-lg-xl      992 - 1200
  screen-md-lg      768 - 992
  screen-sm-md      576 - 768
  screen-xs-sm      0 - 576

  screen-min-lg     992+
  screen-min-md     768+
  screen-min-sm     576+

  screen-max-sm     -576
  screen-max-md     -768
  screen-max-lg     -992
  screen-max-xl     -1200
*/ 
.data-box{
    display: flex;
    flex-direction: row;
    align-items: center;

    @include screen-max-md{ 
        flex-direction: column;
    }

    h2{
        font-size: 28px;
        line-height: 1.14;

        @include screen-max-md{ 
            text-align: center;
        }
    }
    &__file{
        margin-left: 50px;
        margin-right: 50px;
        color: $color-dark;
        font-size: 18px;
        line-height: 1.5;

        @include screen-max-md{ 
            margin-bottom: 16px;
        }

    }
}
.data-container{
    margin-bottom: 40px;
}