/*
  xs: 0			 - 576px
  sm: em(576px), - 768px
  md: em(768px), - 992px
  lg: em(992px), - 1200px
  xl: em(1200px) - .... 

  screen-min-xl     1200+
  screen-lg-xl      992 - 1200
  screen-md-lg      768 - 992
  screen-sm-md      576 - 768
  screen-xs-sm      0 - 576

  screen-min-lg     992+
  screen-min-md     768+
  screen-min-sm     576+

  screen-max-sm     -576
  screen-max-md     -768
  screen-max-lg     -992
  screen-max-xl     -1200
*/ 

.pos-1--1{
    margin-left: -35%;
    border-top: 5px solid #dd6f0c;
    border-right: 5px solid #df7411;

    @include screen-lg-xl{
        margin-left: -31%;
    }
    @include screen-md-lg{
        margin-left: -23%;
        border-top: 5px solid #e07a19;
        border-right: 5px solid #e08023;
    }
    @include screen-sm-md{
        margin-left: -23%;
        border-top: 5px solid #df7919;
        border-right: 5px solid #e18225;
    }
    @include screen-xs-sm{
        margin-left: 0%;
        border-top: 5px solid #e28d34;
        border-right: 5px solid #e39540;
    }
}
.pos-1--2{
    margin-left: -35%;
    border-top: 5px solid #dd6f0c;
    border-right: 5px solid #df7411;

    @include screen-lg-xl{
        margin-left: 3%;
        border-top: 5px solid #e28f38;
        border-right: 5px solid #e3943f;
    }
    @include screen-md-lg{
        margin-left: -23%;
        border-top: 5px solid #e07a19;
        border-right: 5px solid #e08023;
    }
    @include screen-sm-md{
        margin-left: -23%;
        border-top: 5px solid #df7919;
        border-right: 5px solid #e18225;
    }
    @include screen-xs-sm{
        margin-left: 0%;
        border-top: 5px solid #e28d34;
        border-right: 5px solid #e39540;
    }
}
.pos-1--3{
    margin-left: -35%;
    border-top: 5px solid #dd6f0c;
    border-right: 5px solid #df7411;

    @include screen-lg-xl{
        margin-left: 37%;
        border-top: 5px solid #e6ac60;
        border-right: 5px solid #e6b167;
    }
    @include screen-md-lg{
        margin-left: -23%;
        border-top: 5px solid #e07a19;
        border-right: 5px solid #e08023;
    }
    @include screen-sm-md{
        margin-left: -23%;
        border-top: 5px solid #df7919;
        border-right: 5px solid #e18225;
    }
    @include screen-xs-sm{
        margin-left: 0%;
        border-top: 5px solid #e28d34;
        border-right: 5px solid #e39540;
    }
}
.pos-2--2{
    margin-left: -10%;
    border-top: 5px solid #e08429;
    border-right: 5px solid #e2882f;

    @include screen-lg-xl{
        margin-left: 3%;
        border-top: 5px solid #e28f38;
        border-right: 5px solid #e3943f;
    }
    @include screen-md-lg{
        margin-left: 27%;
        border-top: 5px solid #e4a454;
        border-right: 5px solid #e5aa5d;
    }
    @include screen-sm-md{
        margin-left: 25%;
        border-top: 5px solid #e4a251;
        border-right: 5px solid #e5aa5d;
    }
    @include screen-xs-sm{
        margin-left: 0%;
        border-top: 5px solid #e28d34;
        border-right: 5px solid #e39540;
    }
}
.pos-2--3{
    margin-left: -10%;
    border-top: 5px solid #e08429;
    border-right: 5px solid #e2882f;

    @include screen-lg-xl{
        margin-left: 37%;
        border-top: 5px solid #e6ac60;
        border-right: 5px solid #e6b167;
    }
    @include screen-md-lg{
        margin-left: 27%;
        border-top: 5px solid #e4a454;
        border-right: 5px solid #e5aa5d;
    }
    @include screen-sm-md{
        margin-left: 25%;
        border-top: 5px solid #e4a251;
        border-right: 5px solid #e5aa5d;
    }
    @include screen-xs-sm{
        margin-left: 0%;
        border-top: 5px solid #e28d34;
        border-right: 5px solid #e39540;
    }
}
.pos-2--1{
    margin-left: -10%;
    border-top: 5px solid #e08429;
    border-right: 5px solid #e2882f;

    @include screen-lg-xl{
        margin-left: -31%;
        border-top: 5px solid #df7310;
        border-right: 5px solid #de7818;
    }
    @include screen-md-lg{
        margin-left: 27%;
        border-top: 5px solid #e4a454;
        border-right: 5px solid #e5aa5d;
    }
    @include screen-sm-md{
        margin-left: 25%;
        border-top: 5px solid #e4a251;
        border-right: 5px solid #e5aa5d;
    }
    @include screen-xs-sm{
        margin-left: 0%;
        border-top: 5px solid #e28d34;
        border-right: 5px solid #e39540;
    }
}
.pos-3--3{
    margin-left: 15%;
    border-top: 5px solid #e29947;
    border-right: 5px solid #e49e4c;

    @include screen-lg-xl{
        margin-left: 37%;
        border-top: 5px solid #e5ab60;
        border-right: 5px solid #e5b166;
    }
    @include screen-md-lg{
        margin-left: -23%;
        border-top: 5px solid #df7919;
        border-right: 5px solid #df8022;
    }
    @include screen-sm-md{
        margin-left: -23%;
        border-top: 5px solid #df7819;
        border-right: 5px solid #e18225;
    }
    @include screen-xs-sm{
        margin-left: 0%;
        border-top: 5px solid #e28d34;
        border-right: 5px solid #e39540;
    }
}
.pos-3--1{
    margin-left: 15%;
    border-top: 5px solid #e29947;
    border-right: 5px solid #e49e4c;

    @include screen-lg-xl{
        margin-left: -31%;
        border-top: 5px solid #de7310;
        border-right: 5px solid #de7818;
    }
    @include screen-md-lg{
        margin-left: -23%;
        border-top: 5px solid #df7919;
        border-right: 5px solid #df8022;
    }
    @include screen-sm-md{
        margin-left: -23%;
        border-top: 5px solid #df7819;
        border-right: 5px solid #e18225;
    }
    @include screen-xs-sm{
        margin-left: 0%;
        border-top: 5px solid #e28d34;
        border-right: 5px solid #e39540;
    }
}
.pos-3--2{
    margin-left: 15%;
    border-top: 5px solid #e29947;
    border-right: 5px solid #e49e4c;

    @include screen-lg-xl{
        margin-left: 3%;
        border-top: 5px solid #e28f38;
        border-right: 5px solid #e3943f;
    }
    @include screen-md-lg{
        margin-left: -23%;
        border-top: 5px solid #df7919;
        border-right: 5px solid #df8022;
    }
    @include screen-sm-md{
        margin-left: -23%;
        border-top: 5px solid #df7819;
        border-right: 5px solid #e18225;
    }
    @include screen-xs-sm{
        margin-left: 0%;
        border-top: 5px solid #e28d34;
        border-right: 5px solid #e39540;
    }
}
.pos-4--1{
    margin-left: 40%;
    border-top: 5px solid #e6af64;
    border-right: 5px solid #e7b36a;

    @include screen-lg-xl{
        margin-left: -32%;
        border-top: 5px solid #de720f;
        border-right: 5px solid #de7716;
    }
    @include screen-md-lg{
        margin-left: 28%;
        border-top: 5px solid #e4a455;
        border-right: 5px solid #e6ab5e;
    }
    @include screen-sm-md{
        margin-left: 26%;
        border-top: 5px solid #e4a252;
        border-right: 5px solid #e6ab5f;
    }
    @include screen-xs-sm{
        margin-left: 0%;
        border-top: 5px solid #e28d34;
        border-right: 5px solid #e39540;
    }
}
.pos-4--2{
    margin-left: 40%;
    border-top: 5px solid #e6af64;
    border-right: 5px solid #e7b36a;

    @include screen-lg-xl{
        margin-left: 3%;
        border-top: 5px solid #e28f38;
        border-right: 5px solid #e3943f;
    }
    @include screen-md-lg{
        margin-left: 28%;
        border-top: 5px solid #e4a455;
        border-right: 5px solid #e6ab5e;
    }
    @include screen-sm-md{
        margin-left: 26%;
        border-top: 5px solid #e4a252;
        border-right: 5px solid #e6ab5f;
    }
    @include screen-xs-sm{
        margin-left: 0%;
        border-top: 5px solid #e28d34;
        border-right: 5px solid #e39540;
    }
}
.pos-4--3{
    margin-left: 40%;
    border-top: 5px solid #e6af64;
    border-right: 5px solid #e7b36a;

    @include screen-lg-xl{
        margin-left: 37%;
        border-top: 5px solid #e5ab60;
        border-right: 5px solid #e5b166;
    }
    @include screen-md-lg{
        margin-left: 28%;
        border-top: 5px solid #e4a455;
        border-right: 5px solid #e6ab5e;
    }
    @include screen-sm-md{
        margin-left: 26%;
        border-top: 5px solid #e4a252;
        border-right: 5px solid #e6ab5f;
    }
    @include screen-xs-sm{
        margin-left: 0%;
        border-top: 5px solid #e28d34;
        border-right: 5px solid #e39540;
    }
}
/***************************************************/
.pos-1_3--1{
    margin-left: -30%;
    border-top: 5px solid #de7412;
    border-right: 5px solid #df7818;

    @include screen-md-lg{
        margin-left: -23%;
        border-top: 5px solid #df7919;
        border-right: 5px solid #df8022;
    }
    @include screen-max-md{
        margin-left: 0%;
        border-top: 5px solid #e28d34;
        border-right: 5px solid #e39540;
    }
}
.pos-1_3--2{
    margin-left: -30%;
    border-top: 5px solid #de7412;
    border-right: 5px solid #df7818;

    @include screen-md-lg{
        margin-left: 28%;
        border-top: 5px solid #e4a454;
        border-right: 5px solid #e5aa5d;
    }
    @include screen-max-md{
        margin-left: 0%;
        border-top: 5px solid #e28d34;
        border-right: 5px solid #e39540;
    }
}
.pos-2_3--2{
    margin-left: 3%;
    border-top: 5px solid #e39038;
    border-right: 5px solid #e3933e;

    @include screen-md-lg{
        margin-left: 28%;
        border-top: 5px solid #e4a454;
        border-right: 5px solid #e5aa5d;
    }
    @include screen-max-md{
        margin-left: 0%;
        border-top: 5px solid #e28d34;
        border-right: 5px solid #e39540;
    }
}
.pos-2_3--1{
    margin-left: 3%;
    border-top: 5px solid #e39038;
    border-right: 5px solid #e3933e;

    @include screen-md-lg{
        margin-left: -23%;
        border-top: 5px solid #df7919;
        border-right: 5px solid #df8022;
    }
    @include screen-max-md{
        margin-left: 0%;
        border-top: 5px solid #e28d34;
        border-right: 5px solid #e39540;
    }
}
.pos-3_3--1{
    margin-left: 37%;
    border-top: 5px solid #e5ab60;
    border-right: 5px solid #e6b166;

    @include screen-md-lg{
        margin-left: -23%;
        border-top: 5px solid #df7919;
        border-right: 5px solid #df8022;
    }
    @include screen-max-md{
        margin-left: 0%;
        border-top: 5px solid #e28d34;
        border-right: 5px solid #e39540;
    }
}
.pos-3_3--2{
    margin-left: 37%;
    border-top: 5px solid #e5ab60;
    border-right: 5px solid #e6b166;

    @include screen-md-lg{
        margin-left: 28%;
        border-top: 5px solid #e4a454;
        border-right: 5px solid #e5aa5d;
    }
    @include screen-max-md{
        margin-left: 0%;
        border-top: 5px solid #e28d34;
        border-right: 5px solid #e39540;
    }
}
/***************************************************/
.reverse-pos-1{
    top: -48px;
    left: 15%;
    transform: translateX(-50%) rotate(-45deg);
    border-top: 5px solid #fcb55e;
    border-right: 5px solid #fbb259;
    @include screen-lg-xl{
        border-top: 5px solid #fdbd69;
        border-right: 5px solid #fcb862;
    }
    @include screen-max-md{
        left: 50%;
        border-top: 5px solid #faa84d;
        border-right: 5px solid #f89c3c;
    }
}
.reverse-pos-2{
    top: -48px;
    left: 50%;
    transform: translateX(-50%) rotate(-45deg);
    border-top: 5px solid #f9a244;
    border-right: 5px solid #f99f40;
    @include screen-max-md{
        left: 50%;
        border-top: 5px solid #faa84d;
        border-right: 5px solid #f89c3c;
    } 
} 
.reverse-pos-3{
    top: -48px;
    left: 85%;
    transform: translateX(-50%) rotate(-45deg);
    border-top: 5px solid #f78f29;
    border-right: 5px solid #f78c25;
    @include screen-max-md{
        left: 50%;
        border-top: 5px solid #faa84d;
        border-right: 5px solid #f89c3c;
    }
}