/*
  xs: 0			 - 576px
  sm: em(576px), - 768px
  md: em(768px), - 992px
  lg: em(992px), - 1200px
  xl: em(1200px) - .... 

  screen-min-xl     1200+
  screen-lg-xl      992 - 1200
  screen-md-lg      768 - 992
  screen-sm-md      576 - 768
  screen-xs-sm      0 - 576

  screen-min-lg     992+
  screen-min-md     768+
  screen-min-sm     576+

  screen-max-sm     -576
  screen-max-md     -768
  screen-max-lg     -992
  screen-max-xl     -1200
*/ 
.prague-container{
    background: $color-light;
    padding-top: 80px;
    padding-bottom: 280px;
}
.analyza-container{
    position: relative;

    a.btn{
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        transition: background-image 0.3s ease-in-out;

        &:hover > .fac-print{
            background: url('./../img/icons/printer-white.svg');
        }

        @include screen-max-md{
            position: relative;
        }
    }
} 
.print-container{
    a.btn{
        transition: background-image 0.3s ease-in-out;
        position: relative;
        &:hover > .fac-print{
            background: url('./../img/icons/printer-white.svg');
        }
    }
}