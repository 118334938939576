#czechmap{
    height: 500px;
    @include screen-lg-xl{
		height: 420px;
	}
    @include screen-md-lg{
		height: 320px;
	}
    @include screen-sm-md{
        height: 460px;
    }
    @include screen-max-sm{
        height: 500px;
    }
    path{
        &.current_point{
            fill: $map-current-point;
        } 
        &.current_point_bg{
            fill: $map-current-point-bg;
        }
    }
    g {
        .svg_bg{
            &:not(.active):hover{
                filter: drop-shadow(2px 10px 10px rgba(0, 0, 0, 0.35));
            }
            fill: $map-bg;
            stroke: $map-border;
            transform-origin: center center;
            transform-box: fill-box;
            transition:  0.5s;
            cursor: pointer;
        }
        .svg_inactive{
            fill: $color-white;
            stroke: $map-inactive-border;
        }
        .svg_current{
            fill: $color-inactive;
            stroke: $map-inactive-border;
        }
    }
} 
@media (max-width: 490px) {
    #czechmap{
        height: 430px;
    }
}
@media (max-width: 420px) {
    #czechmap{
        height: 370px;
    }
}